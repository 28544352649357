import React, {useState} from "react";
import {AxiosPromise} from "axios";
import {axiosInstance} from "../api/instance";
import {store} from "../store";

const SingleFileUploader = ({update, fileName}: any) => {
    // const [file, setFile] = useState<File | null>(null);
    const [file, setFile] = useState<any>();

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFile(e.target.files[0]);
        }
    };

    const handleUpload = () => {
        let formData = new FormData();
        formData.append("file", file);
        setFile(null);
        const messages = (): AxiosPromise =>
            axiosInstance.post("api/file/upload", formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

        const request = async () => {
            try {
                await messages().then((response) => {
                    console.dir(response.data);
                    update();
                }, () => {
                    // this.setState((prevState) => ({count: prevState.count - 1}))
                });
            } catch (e: any) {
                console.error(e);
            }
        };
        request();
        // We will fill this out later
    };

    const logName: any = store.getState().auth.profileData?.profile;

    return (
        <>
            <div className="text-start" style={{display: 'inline-block', width: '670px'}}>
                <div className="me-2" style={{display: 'inline-block'}}>
                    {!file && <input style={{width: '300px'}} className="form-control" id="file" type="file"
                           onChange={handleFileChange} accept=".project"/>}
                </div>
                {file && <button style={{display: 'inline-block'}} className="btn btn-secondary" onClick={handleUpload}>Вивантажити файл: {file.name}</button>}
                &nbsp;&nbsp;user:&nbsp;<b style={{color:'green', fontSize:'large'}}>{logName.login}</b>
                &nbsp;&nbsp;file:&nbsp;<b style={{color:'blueviolet', fontSize:'large'}}>{fileName}</b>
            </div>
        </>
    );
};

export default SingleFileUploader;
