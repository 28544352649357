import React, {useEffect} from "react";
import "./App.css";
import Header from "./pages/dashboard/Header";
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import {getProfile} from "./store/auth/actionCreators";
import {IRootState, useAppDispatch} from "./store";
import Order from "./pages/order/Order";
import {useSelector} from "react-redux";
import LoginForm from "./pages/login/LoginForm";

function App() {
    const dispatch = useAppDispatch();
    const isLoading: boolean = useSelector(
        (state: IRootState) => state.auth.profileData.isLoading);

    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);

    const authContentShow: boolean = useSelector(
        (state: IRootState) => !!state.auth.authData.accessToken);

    if (isLoading) {
        return (
            <div>
                <div className="spin-wrapper">
                    <div className="spinner"></div>
                    <span>Loading...</span>
                </div>
            </div>
        );
    }

    return (
        <Router>
            <Routes>
                {authContentShow
                    ?
                    <>
                        <Route path="/" element={<Order/>}/>
                        <Route path="/dashboard" element={<Header/>}/>
                        <Route path="/id/:id" element={<Header/>}/>
                        <Route path="*" element={<Navigate to="/"/>}/>
                    </>
                    :
                    <Route path="*" element={<LoginForm/>}/>
                }
                {/*<Route path="/" element={<LeftSideBar/>}/>*/}
                {/*<Route path="/login" element={<AppContent/>}/>*/}
                {/*<Route path="/dashboard" element={<Header/>}/>*/}
                {/*<Route path="/id/:id" element={<Header/>}/>*/}
                {/*<Route path="/p" element={<PartShablon/>}/>*/}
                {/*<Route path="/o" element={<Order/>}/>*/}
            </Routes>
        </Router>
    );
}

export default App;
