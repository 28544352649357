import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IProps1} from "../pages/order/Part";

export interface ScState {
    sc: IProps1[]
}

const initialState: ScState = {
    sc: []
};

export const scrollReducer = createSlice({
    name: "scroll",
    initialState: {
            ...initialState
        }
    ,
    reducers: {
        setSC: (state, action: PayloadAction<IProps1[]>): ScState => ({
            ...state,
            sc: action.payload,
        })
    },
});

export const {
    setSC
} = scrollReducer.actions;

export default scrollReducer.reducer;
