const Endpoints = {
  AUTH: {
    LOGIN: "/api/login",
    REGISTER: "/api/register",
    REFRESH: "/api/refresh",
    PROFILE: "/api/profile",
    LOGOUT: "/api/logout"
  },
  DATA: {
    MESSAGE: "/api/messages",
  },
};

export default Endpoints;
