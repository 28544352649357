import React, {useState} from "react";
import {AxiosPromise} from "axios";
import {axiosInstance} from "../api/instance";

interface IResponse {
    blob: Blob;
}
const FileDownloader = () => {

    const [isDownload, setIsDownload] = useState(false);

    const handleDownload = () => {
        setIsDownload(true);
        const messages = (): AxiosPromise =>
            axiosInstance.get("api/file/zip", {responseType:"blob"}).then(res=>{console.log(res.headers)
            return res});
        const request = async () => {
            try {
                await messages()
                    .then((res) => {
                        const link = document.createElement('a');
                        const url = URL.createObjectURL(res.data);
                        // const fileNameHeader = "x-suggested-filename";
                        const fileName = res.headers["content-disposition"].split('*=UTF-8\'\'')[1]
                        link.href = url;
                        console.log(link)
                        link.download = decodeURIComponent(fileName);
                        link.click();
                        URL.revokeObjectURL(url);
                    });
                // }, () => {
                //     // this.setState((prevState) => ({count: prevState.count - 1}))
                // });
                setIsDownload(false);
            } catch (e: any) {
                console.error(e);
            }
        };
        request();
    };

    return (
        <>
        {isDownload ? <div style={{display: 'inline-block', width:"208px"}}></div>:<button style={{display:'inline-block'}} className="btn btn-secondary" onClick={handleDownload}>Завантажити файли програм</button>}
        </>
    );
};

export default FileDownloader;
