import * as React from "react";

export default function Buttons(props: any) {
    return (
        <>
            {/* <div className="col-md-12 text-center">*/}
            {/*          /!*<div style={{display:'inline-block'}}>*!/*/}
            {/*        <button*/}
            {/*          className="btn btn-primary"*/}
            {/*          style={{ margin: "10px" }}*/}
            {/*          onClick={props.login}*/}
            {/*        >*/}
            {/*          {props.name}*/}
            {/*        </button>*/}
                <button
                    className="btn btn-dark"
                    style={{margin: "10px"}}
                    onClick={props.logout}
                >
                    Вийти
                </button>
            {/*</div>*/}
        </>
    );
}
