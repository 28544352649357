import {Dispatch} from "@reduxjs/toolkit";
import api from "../../api";
import {ILoginRequest, ILoginResponse, IRegisterRequest,} from "../../api/auth/types";
import {
    loadProfileFailure,
    loadProfileStart,
    loadProfileSuccess,
    loginFailure,
    loginStart,
    loginSuccess,
    logoutSuccess,
    refreshFailure,
    refreshStart,
    refreshSuccess,
} from "./authReducer";
import {history} from "../../utils/history";
import {store} from "..";
import {AxiosPromise} from "axios";
import {isTokenExpired} from "../../utils/jwt";

export const loginUser =
  (data: ILoginRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(loginStart());
      const res = await api.auth.login(data);
      dispatch(loginSuccess(res.data.accessToken));
      dispatch(getProfile());
    } catch (e: any) {
      console.error(e);
      dispatch(loginFailure(e.message));
    }
  };

export const registerUser =
  (data: IRegisterRequest) =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(loginStart());
      const res = await api.auth.register(data);
      dispatch(loginSuccess(res.data.accessToken));
      dispatch(getProfile());
    } catch (e: any) {
      console.error(e);
      dispatch(loginFailure(e.message));
    }
  };

export const logoutUser =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      await api.auth.logout();
      dispatch(logoutSuccess());
      history.push("/login");
    } catch (e) {
      console.error(e);
    }
  };

export const getProfile =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      dispatch(loadProfileStart());
      const res = await api.auth.getProfile();
      dispatch(loadProfileSuccess(res.data));
    } catch (e: any) {
      console.error(e);
      dispatch(loadProfileFailure(e.message));
    }
  };

// don't have race condition
let refreshTokenRequest: AxiosPromise<ILoginResponse> | null = null;

export const getAccessToken =
  () =>
  async (dispatch: Dispatch<any>): Promise<string | null> => {
    try {
      const accessToken = store.getState().auth.authData.accessToken;
      if (!accessToken || isTokenExpired(accessToken)) {
          dispatch(refreshStart());
        if (refreshTokenRequest === null) {
          refreshTokenRequest = api.auth.refreshToken();
        }
        const res = await refreshTokenRequest;
        refreshTokenRequest = null;
        dispatch(refreshSuccess(res.data.accessToken));
        return res.data.accessToken;
      }
      return accessToken;
    } catch (e: any) {
      console.error(e);
      dispatch(refreshFailure(e.message));
      refreshTokenRequest = null;
      return null;
    }
  };
