import * as React from "react";
import {ReactElement, useState} from "react";
import img1 from "../../img/loading.svg";
import "./Part.css";
import {useNavigate} from "react-router-dom";

interface IEdge {
    top: string | null;
    bottom: string | null;
    left: string | null;
    right: string | null;
}

interface IOperations {
    drill: number;
    mill: number;
    groove: number;
}

export interface IProps1 {
    id?: number;
    number: number;
    active: boolean;
    name?: string;
    material: Map<string, string>;
    selectedMaterial?: string;
    quantity: number;
    byTexture?: boolean;
    length?: number;
    width?: number;
    thickness?: number;
    edges: IEdge;
    operations: IOperations;
    // edges?: [id: string, name: string];
    // edge?: [side: string, id: string];
    // operations?: [name: string, quantity: number];
    func?: any;
    img: string | TrustedHTML;
    navigate:any
    index:number
}

export default function Part (props:IProps1){
    const navigate = useNavigate();

    const [active, setActive] = useState(props.active)
    const [name, setName] = useState(props.name)
    const [selectedMaterial, setSelectedMaterial] = useState(props.selectedMaterial)
    const [byTexture, setByTexture] = useState(props.byTexture)
    const [quantity, setQuantity] = useState(props.quantity)
    const [length, setLength] = useState(props.length)
    const [width, setWidth] = useState(props.width)

    const handleActive = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActive(e.target.checked);
    };

    const handleName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleMaterial = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedMaterial(e.target.value);
    };

    const handleTexture = (e: React.ChangeEvent<HTMLInputElement>) => {
        setByTexture(e.target.checked);
    };

    const handleQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
        setQuantity(e.target.valueAsNumber);
    };

    const handleLength = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLength(e.target.valueAsNumber);
    };

    const handleWidth = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWidth(e.target.valueAsNumber);
    };

    const getMaterials = (): ReactElement[] => {
        const forEachMaterials: ReactElement[] = [];
        props.material.forEach((value, key) => {
            forEachMaterials.push(
                <option key={key} value={key}>
                    {value}
                </option>,
            );
        });
        return forEachMaterials;
    };

    const edgeSW = (string: string) => {
        switch (string) {
            case 'top':
                return props.edges.top;
            case 'bottom':
                return props.edges.bottom;
            case 'left':
                return props.edges.left;
            case 'right':
                return props.edges.right
        }
    }

    const getEdges = (side: string): ReactElement[] => {
        const edgesElement: ReactElement[] = [];
        const edge = edgeSW(side)
        if (edge) {
            edgesElement.push(
                <option key={side+1}>
                     {edge}
                 </option>,
                 <option key={side} defaultValue={"none"}>без крайки</option>
             );
         } else {
            edgesElement.push(
                <option key={side} defaultValue={"none"}>без крайки</option>
            );
         }
        return edgesElement;
    };

// loader = async () => {
//     const user = await getUser();
//     if (!user) {
//         return redirect("/login");
//     }
//     return null;
// };

        // if (this.state.redirect) {return redirect("/login");}
        return (
            <>
                <tr className="tr1"
                    draggable={true}
                    // style={{ opacity: isDragging ? 0 : 1 }}
                >
                    <td className="td1" style={{paddingTop: "30px"}}
                        // style={{verticalAlign: "middle"}}
                    >
                        {/*<span style={{display: "block"}}>{props.number}</span>*/}
                        <span style={{display: "block"}}>{props.index}</span>
                        <div className="checkbox-wrapper-31">
                            <input
                                type="checkbox"
                                checked={active}
                                // defaultChecked={this.state.active}
                                onChange={handleActive}
                            />
                            <svg viewBox="0 0 35.6 35.6">
                                <circle
                                    className="background"
                                    cx="17.8"
                                    cy="17.8"
                                    r="17.8"
                                ></circle>
                                <circle
                                    className="stroke"
                                    cx="17.8"
                                    cy="17.8"
                                    r="14.37"
                                ></circle>
                                <polyline
                                    className="check"
                                    points="11.78 18.12 15.55 22.23 25.17 12.87"
                                ></polyline>
                            </svg>
                        </div>
                    </td>
                    <td className="td1"
                        // style={{verticalAlign: "middle"}}
                    >
                        {props.img.toString().length < 40
                            ? <img src={img1} alt=""></img>
                            // :<div dangerouslySetInnerHTML={{__html: this.state.img}}></div>}
                            // :<img style={{maxHeight:"100px",width:"100px", maxWidth:"100%"}} src={"data:image/svg+xml;base64,"+this.state.img} alt=""/>}
                            :<img onClick={() => {console.log("Обробити");navigate("/id/1?id="+props.id)}}
                                  src={"data:image/svg+xml;base64,"+props.img} alt=""/>}
                                  {/*src={"<img src='data:image/svg+xml;utf8,"+props.img} alt=""/>}*/}

                    </td>
                    <td className="td1">
                        <input
                            className="part-size"
                            required
                            type="text"
                            defaultValue={name}
                            onChange={handleName}
                        />
                        <label placeholder="Назва деталi"></label>
                    </td>
                    <td className="td1">
                        <select
                            className="part-size"
                            required
                            id="material"
                            // defaultValue={
                            //     this.state.material.has(this.state.selectedMaterial)
                            //         ? this.state.selectedMaterial
                            //         : "none"
                            // }
                            onChange={handleMaterial}
                        >
                            <option disabled value="none"></option>
                            {getMaterials()}
                        </select>
                        <label placeholder="Матерiал"></label>
                        <input
                            className={"part-size"}
                            required
                            type="number"
                            min="0"
                            step="1"
                            value={quantity}
                            onChange={handleQuantity}
                        />
                        <label placeholder="Кількість"></label>
                        <span
                            style={{
                                fontSize: "small",
                                fontWeight: "bold",
                                color: "#898989",
                            }}
                        >
                  Текстура по довжинi
                </span>
                        <div className="checkbox-wrapper-2">
                            <input
                                type="checkbox"
                                className="sc-gJwTLC ikxBAC"
                                defaultChecked={byTexture}
                                onChange={handleTexture}
                            />
                        </div>
                    </td>
                    <td className="td1">
                        <input
                            className="part-size"
                            required
                            type="number"
                            min="0"
                            step="0.1"
                            value={length}
                            onChange={handleLength}
                        />
                        <label placeholder="Довжина"></label>
                        <input
                            className="part-size"
                            required
                            type="number"
                            min="0"
                            step="0.1"
                            value={width}
                            onChange={handleWidth}
                        />
                        <label placeholder="Ширина"></label>
                        <input
                            className="part-size"
                            required
                            type="number"
                            min="0"
                            step="0.1"
                            readOnly={true}
                            value={props.thickness}
                            // onChange={this.handleThickness}
                        />
                        <label placeholder="Товщина"></label>
                    </td>
                    <td className="td1">
                        <svg
                            display="inline-block"
                            transform="rotate(0)"
                            width="24"
                            height="24"
                        >
                            <use xlinkHref="#edge"></use>
                        </svg>
                        <select
                            style={{display: "inline-block"}}
                            className="edge-select"
                            required
                            id="material"
                        >
                            {getEdges("top")}
                        </select>
                        <label placeholder="Матерiал"></label>
                        <svg
                            display="inline-block"
                            transform="rotate(180)"
                            width="24"
                            height="24"
                        >
                            <use xlinkHref="#edge"></use>
                        </svg>
                        <select
                            style={{display: "inline-block"}}
                            className="edge-select"
                            required
                            id="material"
                        >
                            {getEdges("bottom")}
                        </select>
                        <svg
                            display="inline-block"
                            transform="rotate(270)"
                            width="24"
                            height="24"
                        >
                            <use xlinkHref="#edge"></use>
                        </svg>
                        <select
                            style={{display: "inline-block"}}
                            className="edge-select"
                            required
                            id="material"
                        >
                            {getEdges("left")}
                        </select>
                        <svg
                            display="inline-block"
                            transform="rotate(90)"
                            width="24"
                            height="24"
                        >
                            <use xlinkHref="#edge"></use>
                        </svg>
                        <select
                            style={{display: "inline-block"}}
                            className="edge-select"
                            required
                            id="material"
                        >
                            {getEdges("right")}
                        </select>
                    </td>
                    <td style={{textAlign: "right"}} className="td1">
                        {props.operations.drill ? <span className="obrob">Отвори - {props.operations.drill} шт.</span>:''}
                        {props.operations.mill ? <span className="obrob">Фрез. рiз - {props.operations.mill} шт.</span>:''}
                        {props.operations.groove ? <span className="obrob">Паз пласть - {props.operations.groove} шт.</span>:''}
                        {/*{this.state.operations.groove && (<span className="obrob">Паз торець - {this.state.operations.drill} шт.</span>)}*/}
                    </td>
                    <td className="td1">
                        <div className="icons">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="20"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    d="M464 0c26.5 0 48 21.5 48 48v288c0 26.5-21.5 48-48 48H176c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48h288M176 416c-44.1 0-80-35.9-80-80V128H48c-26.5 0-48 21.5-48 48v288c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-48H176z"/>
                            </svg>
                            <span style={{fontWeight: "bold"}}>Копiювати</span>
                        </div>
                        <div className="icons" onClick={() => {
                            console.log("Обробити");
                            navigate("/id/1?id="+props.id)
                        }}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                                <path
                                    d="M571.3 193.9l-22.6-22.6c-6.3-6.3-16.4-6.3-22.6 0l-11.3 11.3-28.9-28.9c5.6-21.3 .4-44.9-16.4-61.6l-45.3-45.3c-62.5-62.5-163.8-62.5-226.3 0l90.5 45.3v18.8c0 17 6.7 33.3 18.8 45.3l49.1 49.1c16.7 16.7 40.3 22 61.6 16.4l28.9 28.9-11.3 11.3c-6.3 6.3-6.3 16.4 0 22.6l22.6 22.6c6.3 6.3 16.4 6.3 22.6 0l90.5-90.5c6.2-6.2 6.2-16.4 0-22.6zm-286.7-15.2c-3.7-3.7-6.8-7.8-9.9-12L19.6 405c-25.6 23.9-26.3 64.2-1.5 88.9s65.1 24.1 88.9-1.5l238.1-255.1c-4-2.9-7.9-5.9-11.4-9.4l-49.1-49.1z"/>
                            </svg>
                            <span style={{fontWeight: "bold"}}>Обробити</span>
                        </div>
                        <div className="icons">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M212.3 224.3H12c-6.6 0-12-5.4-12-12V12C0 5.4 5.4 0 12 0h48c6.6 0 12 5.4 12 12v78.1C117.8 39.3 184.3 7.5 258.2 8c136.9 1 246.4 111.6 246.2 248.5C504 393.3 393.1 504 256.3 504c-64.1 0-122.5-24.3-166.5-64.2-5.1-4.6-5.3-12.6-.5-17.4l34-34c4.5-4.5 11.7-4.7 16.4-.5C170.8 415.3 211.6 432 256.3 432c97.3 0 176-78.7 176-176 0-97.3-78.7-176-176-176-58.5 0-110.3 28.5-142.3 72.3h98.3c6.6 0 12 5.4 12 12v48c0 6.6-5.4 12-12 12z"/>
                            </svg>
                            <span style={{fontWeight: "bold"}}>Обернути</span>
                        </div>
                        <div className="icons">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                    d="M109.5 244l134.6-134.6-44.1-44.1-61.7 61.7a7.9 7.9 0 0 1 -11.2 0l-11.2-11.2c-3.1-3.1-3.1-8.1 0-11.2l61.7-61.7-33.6-33.7C131.5-3.1 111.4-3.1 99 9.3L9.3 99c-12.4 12.4-12.4 32.5 0 44.9l100.2 100.2zm388.5-116.8c18.8-18.8 18.8-49.2 0-67.9l-45.3-45.3c-18.8-18.8-49.2-18.8-68 0l-46 46 113.2 113.2 46-46zM316.1 82.7l-297 297L.3 487.1c-2.5 14.5 10.1 27.1 24.6 24.6l107.5-18.8L429.3 195.9 316.1 82.7zm186.6 285.4l-33.6-33.6-61.7 61.7c-3.1 3.1-8.1 3.1-11.2 0l-11.2-11.2c-3.1-3.1-3.1-8.1 0-11.2l61.7-61.7-44.1-44.1L267.9 402.5l100.2 100.2c12.4 12.4 32.5 12.4 44.9 0l89.7-89.7c12.4-12.4 12.4-32.5 0-44.9z"/>
                            </svg>
                            <span style={{fontWeight: "bold"}}>Креслення</span>
                        </div>
                        <div className="icons" onClick={() => window.location.reload()}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path
                                    d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1 -32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1 -32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1 -32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.7 23.7 0 0 0 -21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0 -16-16z"/>
                            </svg>
                            <span style={{fontWeight: "bold"}}>Видалити</span>
                        </div>
                    </td>
                </tr>
            </>
        );
}
