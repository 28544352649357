import * as React from "react";
import {FormEvent, useEffect, useState} from "react";
import classNames from "classnames";
import {loginUser, registerUser} from "../../store/auth/actionCreators";
import {store, useAppDispatch} from "../../store";
import {setSC} from "../../store/scrollReducer";

const LoginForm = () => {

    const dispatch = useAppDispatch();

    const [login, setLogin] = useState("");
    const [password, setPassword] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [active, setActive] = useState("login");
    const [fail, setFail] = useState(false)

    const handleSubmitLogin = (event: FormEvent) => {
        event.preventDefault();
        dispatch(loginUser({login, password})).then(()=>setFail(true));
    };

    const handleSubmitRegister = (event: FormEvent) => {
        event.preventDefault();
        dispatch(registerUser({firstName, lastName, login, password}));
    };

    useEffect(() => {
        store.dispatch(setSC([]));
    }, []);

    return (
            <div className="row justify-content-center"
                 style={{marginTop:"300px"}}>
                <div className=""
                     style={{width:"300px"}}>
                    {/*<ul className="nav nav-pills justify-content-center m-3">*/}
                    {/*    <li className="nav-item m-1">*/}
                    {/*        <button*/}
                    {/*            className={classNames(*/}
                    {/*                "nav-link",*/}
                    {/*                active === "login" ? "active" : "",*/}
                    {/*            )}*/}
                    {/*            onClick={() => setActive("login")}*/}
                    {/*        >*/}
                    {/*            Вхiд*/}
                    {/*        </button>*/}
                    {/*    </li>*/}
                    {/*    <li className="nav-item m-1">*/}
                    {/*        <button*/}
                    {/*            className={classNames(*/}
                    {/*                "nav-link",*/}
                    {/*                active === "register" ? "active" : "",*/}
                    {/*            )}*/}
                    {/*            onClick={() => setActive("register")}*/}
                    {/*        >*/}
                    {/*            Register*/}
                    {/*        </button>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}
                    <div className="tab-content">
                        <div
                            className={classNames(
                                "tab-pane",
                                "fade",
                                active === "login" ? "show active" : "",
                            )}
                            id="pills-login"
                        >
                            <form onSubmit={handleSubmitLogin}>
                                <div className="form-outline mb-1">
                                    <input
                                        type="login"
                                        id="loginName"
                                        name="login"
                                        className="form-control"
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="loginName">
                                        Логiн
                                    </label>
                                </div>

                                <div className="form-outline">
                                    <input
                                        type="password"
                                        id="loginPassword"
                                        name="password"
                                        className="form-control"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="loginPassword">
                                        Пароль
                                    </label>
                                    {fail &&<div style={{color:'red', fontWeight:'bold'}} className="pb-4 text-center">
                                        Неправiльний логiн або пароль!!!
                                    </div>}
                                </div>
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    style={{float: "right"}}
                                >
                                    Увiйти
                                </button>
                            </form>
                        </div>
                        <form className="row g-3">
                        </form>
                        <div
                            className={classNames(
                                "tab-pane",
                                "fade",
                                active === "register" ? "show active" : "",
                            )}
                            id="pills-register"
                        >
                            <form onSubmit={handleSubmitRegister}>
                                <div className="form-outline">
                                    <input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        className="form-control"
                                        onChange={(e) => setFirstName(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="firstName">
                                        First name
                                    </label>
                                </div>

                                <div className="form-outline">
                                    <input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        className="form-control"
                                        onChange={(e) => setLastName(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="lastName">
                                        Last name
                                    </label>
                                </div>

                                <div className="form-outline">
                                    <input
                                        type="text"
                                        id="login"
                                        name="login"
                                        className="form-control"
                                        onChange={(e) => setLogin(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="login">
                                        Username
                                    </label>
                                </div>

                                <div className="form-outline">
                                    <input
                                        type="password"
                                        id="registerPassword"
                                        name="password"
                                        className="form-control"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <label className="form-label" htmlFor="registerPassword">
                                        Password
                                    </label>
                                </div>

                                <button
                                    type="submit"
                                    className="btn btn-primary btn-block"
                                    style={{float: "right"}}>
                                    Sign in
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default LoginForm
